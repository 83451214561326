<template>
  <v-overlay :value="!isLoaded">
    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'ProgressCircular',

  props: {
    isLoaded: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
}
</script>
